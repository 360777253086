/**
 * Checks if the number provided is a decimal, returns true if paramater is not a number.
 * @param {number} num - The number to check.
 */
export const isDecimal = (num) => {
    if (typeof num !== 'number') return true;
    return num % 1 !== 0;
};

/**
 * Round DOWN a number to the nearest Fraction.
 * this will round this 5.223232 will round up to 5.25 if we use  nearestFracNum(5.223232, 0.15)
 * @param {*} num - number to get nearest
 * @param {*} frac - in decimals - 0.25, 0.5, 0.1
 */
export const nearestFracNum = (num, frac) => {
    if (!isDecimal(frac)) {
        return null;
    }

    const fractNum = Math.round(1 / frac);

    const a = Math.floor(num * fractNum);
    const b = a / fractNum;

    return parseFloat(b.toFixed(frac.toString().split('.')[1].length));
};

/**
 *
 * Height conversion functions
 *
 */

/**
 * Convert string of ft inches to cm
 * @param {string} ftInches - Our ft inches value is separated with a colon. example 4 ft 7 inches will be -> `4:7`.
 */
export const ftInchesToCm = (ft, inch) => {
    const foot = parseFloat(ft);
    const inches = parseFloat(inch);

    const fullInches = foot * 12 + inches;
    const cm = fullInches * 2.54;

    return cm.toFixed(2);
};

/**
 * Convert cm to ft inches string (e.g. '4:7').
 * @param {number} cm - provided cm to convert to ft and inch
 * @param {boolean} niceMessage  - whether we want the return to be a nice message or the default object
 */
export const cmToFtInch = (cm, niceMessage = null) => {
    const inches = cm / 2.54;
    const ft = Math.floor(inches / 12);
    const leftOverInches = inches - ft * 12;

    if (niceMessage) {
        return `${Math.floor(ft)}ft ${Math.round(leftOverInches)}inch${Math.round(leftOverInches) === 1 ? '' : 'es'}`;
    }

    return { ft: Math.floor(ft), inch: Math.round(leftOverInches) };
};

/**
 *
 * Weight conversion functions (Grams)
 *
 */

/**
 * Convert grams to st pounds string (e.g. '4:7').
 * @param {number} grams - provided grams to convert to st and pount
 * @param {boolean} niceMessage  - whether we want the return to be a nice message or the default object
 */
export const gramsToStonePounds = (grams, niceMessage = null) => {
    const pounds = grams / 454;
    const stone = Math.floor(pounds / 14);
    const leftOverPounds = pounds - stone * 14;

    if (niceMessage) {
        return `${Math.floor(stone)}st ${nearestFracNum(leftOverPounds, 0.25)}lb${Math.round(leftOverPounds) === 1 ? '' : 's'}`;
    }

    return { st: Math.floor(stone), lb: nearestFracNum(leftOverPounds, 0.25) };
};

/**
 * Convert string of stone pound to grams
 * @param {string} stPound - Our stone and pounds value is separated with a colon. example 4 stone 7 pounds will be -> `4:7`.
 */
export const stPoundToGrams = (st, lb) => {
    const stone = parseFloat(st);
    const pounds = parseFloat(lb);

    const fullPounds = stone * 14 + pounds;
    const grams = fullPounds * 454;

    return grams;
};

/**
 *
 * Weight conversion functions (KG)
 *
 */

/**
 * Convert kg to st pounds string (e.g. '4:7').
 * @param {number} kg - provided kg to convert to st and pount
 * @param {boolean} niceMessage  - whether we want the return to be a nice message or the default object
 */
export const kgToStonePounds = (kg, niceMessage) => {
    const pounds = kg * 2.205;
    const stone = Math.floor(pounds / 14);
    const leftOverPounds = pounds - stone * 14;

    if (niceMessage) {
        return `${Math.floor(stone)}st ${nearestFracNum(leftOverPounds, 0.25)}lb${Math.round(leftOverPounds) === 1 ? '' : 's'}`;
    }

    return { st: Math.floor(stone), lb: nearestFracNum(leftOverPounds, 0.25) };
};

/**
 * Convert string of stone pound to kg
 * @param {string} stPound - Our stone and pounds value is separated with a colon. example 4 stone 7 pounds will be -> `4:7`.
 */
export const stPoundToKg = (st, lb) => {
    const stone = parseFloat(st);
    const pounds = parseFloat(lb);

    const fullPounds = stone * 14 + pounds;
    const kg = fullPounds / 2.205;

    return kg;
};

/**
 * Calculates a users BMI.
 * @param {number} height - Height of the person.
 * @param {number} weight - Weight of the person.
 */
export const calculateBMI = (height, weight) => (
    parseFloat((parseFloat(weight) / Math.pow(parseFloat(height) / 100, 2)).toFixed(2))
);
// Disabled the ** eslint error above as we don't want to mess with the BMI calculator.
// TODO: Fix the eslint error above and retest it.

/**
 *
 * Weight conversion functions (kg to Grams)
 *
 */
export const gramsToKG = (grams) => {
    if (!grams) return '';
    return grams / 1000;
};

export const kgToGrams = (kg) => {
    if (!kg) return '';
    return kg * 1000;
};

export const roundToNearestHalf = (number) => Math.round(number * 2) / 2;

export const penceToPounds = (pence) => (pence / 100).toFixed(2);
export const poundsToPence = (pounds) => pounds * 100;

export const calculateDifferenceAsPercentage = (firstNumber, secondNumber) => {
    const largestNumber = Math.max(firstNumber, secondNumber);
    const smallestNumber = Math.min(firstNumber, secondNumber);

    const difference = largestNumber - smallestNumber;
    return (difference / largestNumber) * 100;
};

export const formatToNearestTen = (number) => Math.floor(number / 10) * 10;

export const isEven = (n) => (
    n % 2 === 0
);

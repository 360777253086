import { ProductVariant } from '../../../../../../../../types/api/products/Product';

export type InitialVariant = ProductVariant['id'];

/**
 * Filters variants by stock. If there are no variants with stock, return all variants regardless of stock.
 */
export const filterVariantsByStock = (urlFbs: string | undefined | null, variants: ProductVariant[]) => {
    // Checks if the variants should be filtered by stock (fbs).
    if (urlFbs === '0') {
        return variants;
    }

    const variantsWithStock = variants.filter((variant) => variant.quantities.length > 0);
    return variantsWithStock.length ? variantsWithStock : variants;
};

/**
 * Gets the variant ID from the url. If the variant ID is not a number, return null.
 */
export const getUrlVariantId = (urlDefaultVariant?: string | null) => {
    const urlVariantId = parseInt(urlDefaultVariant || '');
    return !Number.isNaN(urlVariantId) ? urlVariantId : null;
};

/**
 * Gets the default variant from a list of variants.
 */
const getDefaultVariant = (variants: ProductVariant[], urlFbs?: string | null, urlDefaultVariant?: string | null, initialVariant?: InitialVariant) => {
    const filteredVariants = filterVariantsByStock(urlFbs, variants);

    // Returns a variant that matches the variant ID that is provided in the function params.
    if (initialVariant) {
        const urlVariant = filteredVariants.find(({ id }) => id === initialVariant);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that matches the variant ID that is provided in the URL.
    const urlVariantId = getUrlVariantId(urlDefaultVariant);
    if (urlVariantId) {
        const urlVariant = filteredVariants.find(({ id }) => id === urlVariantId);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that matches the default that is provided in the URL.
    if (urlDefaultVariant) {
        const urlVariant = filteredVariants.find((variant) => (!Array.isArray(variant.public) ? variant.public.slug : '') === urlDefaultVariant);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that is marked as default. If there is no default variant, return the first variant.
    return filteredVariants.find((variant) => variant.default) || filteredVariants[0];
};

export default getDefaultVariant;
